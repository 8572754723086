import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useMsal, useIsAuthenticated } from '@azure/msal-react';

const msalConfig = {
  auth: {
    clientId: '7292467a-55e5-469f-9c01-2dc518d2d51f',
    authority: 'https://login.microsoftonline.com/6ea71dca-c92d-4aa3-a216-6a92a83c90d7',
    redirectUri: 'http://localhost:3000',
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const App = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <FileConverter />
    </MsalProvider>
  );
};

const FileConverter = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLogin = async () => {
    try {
      await instance.loginPopup();
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  const handleLogout = () => {
    instance.logout();
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setError(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setError('Please select a file');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const accessToken = await instance.acquireTokenSilent({
        scopes: ['User.Read'],
        account: accounts[0],
      });

      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;
        const response = await axios.post('http://138.197.18.20/convert.php', content, {
          headers: { 
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${accessToken.accessToken}`,
          },
          responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'converted.json');
        document.body.appendChild(link);
        link.click();
        link.remove();
      };
      reader.readAsText(file);
    } catch (error) {
      setError('An error occurred during conversion');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">JSONL to JSON Converter</h1>
      {!isAuthenticated ? (
        <button onClick={handleLogin} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Sign in with Azure AD
        </button>
      ) : (
        <>
          <button onClick={handleLogout} className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mb-4">
            Sign out
          </button>
          <form onSubmit={handleSubmit} className="mb-4">
            <input
              type="file"
              onChange={handleFileChange}
              accept=".jsonl"
              className="mb-2 p-2 border rounded"
            />
            <button
              type="submit"
              disabled={loading}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              {loading ? 'Converting...' : 'Convert and Download'}
            </button>
          </form>
          {error && <p className="text-red-500">{error}</p>}
        </>
      )}
    </div>
  );
};

export default App;
